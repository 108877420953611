let TeamInfo = [
    {
      id: 1,
      teamAlt: "Gallery_1",
      teamImg: require("../img/Gallery_1.jpg").default,
    },
    {
      id: 2,
      teamAlt: "Gallery_2",
      teamImg: require("../img/Gallery_2.jpg").default,
    },
    {
      id: 3,
      teamAlt: "Gallery_3",
      teamImg: require("../img/Gallery_3.jpg").default,
    },
    {
      id: 4,
      teamAlt: "Gallery_4",
      teamImg: require("../img/Gallery_4.jpg").default,
    },
  ];
  
  export default TeamInfo;
  