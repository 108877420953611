import React, { useState } from "react";
import "./speakers.css";
import { Container, Row, Col } from "react-bootstrap";
import Speaker from "./Speaker/Speaker";
import speakerInfo from "../../store/SpeakerInfo";
import speakerInfoHide from "../../store/SpeakerInfoHide";
import ln from '../../img/ln.svg';
import gh from "../../img/github_2.svg";
import tw from "../../img/twitter.svg";
import Chaer from "../../img/Speakers/Chaer.png";
import Lofredo from "../../img/Speakers/Lofredo.png";
import Rootstrap from "../../img/Speakers/Rootstrap.png";
import ModalSpeaker from "./ModalSpeaker/ModalSpeaker";

const Speakers = (props) => {
  const [modalShow, setModalShow] = useState(false);

  let speakerElements = speakerInfo.map((spItem, i) => {
    return (
      <Speaker 
        speakerPhoto={spItem.speakerPhoto}
        speakerName={spItem.speakerName}
        speakerTrack={spItem.speakerTrack}
        speakerTrackСlass={spItem.speakerTrackСlass}
        speakerPosition={spItem.speakerPosition}
        speakerLinkedinPath={spItem.speakerLinkedinPath}
        speakerGithubPath={spItem.speakerGithubPath}
        speakerTwitterPath={spItem.speakerTwitterPath}
        speakerWebsitePath={spItem.speakerWebsitePath}
        speakerWebsitePath2={spItem.speakerWebsitePath2}
        speakerAbstractTitle={spItem.speakerAbstractTitle}
        speakerLogo={spItem.speakerLogo}
        speakerAbstractText={spItem.speakerAbstractText}
      />
    );
  });
  let speakerElementsHide = speakerInfoHide.map((spItem) => {
    return (
      <Speaker
        speakerPhoto={spItem.speakerPhoto}
        speakerName={spItem.speakerName}
        speakerTrack={spItem.speakerTrack}
        speakerPosition={spItem.speakerPosition}
        speakerTrackСlass={spItem.speakerTrackСlass}
        speakerLinkedinPath={spItem.speakerLinkedinPath}
        speakerGithubPath={spItem.speakerGithubPath}
        speakerTwitterPath={spItem.speakerTwitterPath}
        speakerAbstractTitle={spItem.speakerAbstractTitle}
        speakerLogo={spItem.speakerLogo}
        speakerAbstractText={spItem.speakerAbstractText}
      />
    );
  });
  function show() {
    let hideblock = document.getElementById("hide");
    let hidebutton = document.getElementById("hidebutton");
    let showbutton = document.getElementById("showbutton");

    hideblock.style.display = "flex";
    hidebutton.style.display = "block";
    showbutton.style.display = "none";
  }

  function hide() {
    let hideblock = document.getElementById("hide");
    let showbutton = document.getElementById("showbutton");
    let hidebutton = document.getElementById("hidebutton");

    hideblock.style.display = "none";
    showbutton.style.display = "block";
    hidebutton.style.display = "none";
  }
  return (
    <div className="speakers" id="speakers">
      <Container>
        <Row>
          <Col>
            <div className="speakers-title">
              <h2 className="title title--black">{"<SPEAKERS AND TOPICS>"}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="speakers-block">{speakerElements}</div>
            <button className="showbutton" onClick={show} id="showbutton">
              SHOW MORE
            </button>
              <div className="speakers-block hide" id="hide">{speakerElementsHide}
               <div className="speaker-main speaker-main-2">
                <div className="speaker speaker-2">
                <div className={`speaker_plashka`}>Junior</div>
                  <div className="speaker-flex">
                    <div>
                      <div className="speaker-photo speaker-photo-1">
                        <img className="speaker-photo-img speaker-photo-img-2" src={Chaer} alt="Speaker-1" />
                        <div className="speaker-social speaker-social-2">
                          <a
                            href='https://www.linkedin.com/in/agustinachaer/'
                            target={"_blank"}
                            rel="noreferrer"
                          >
                              <img src={ln} alt="" />
                          </a>
                          <a
                            href='https://github.com/aguscha333'
                            target={"_blank"}
                            rel="noreferrer"
                          >
                              <img src={gh} alt="Twitter" />
                          </a>
                        </div>
                      </div>
                      <div className="speaker-photo speaker-photo-1">
                        <img className="speaker-photo-img speaker-photo-img-2" src={Lofredo} alt="Speaker-1" />
                        <div className="speaker-social speaker-social-2">
                          <a
                            href='https://www.linkedin.com/in/elizabeth-lofredo-933023154/'
                            target={"_blank"}
                            rel="noreferrer"
                          >
                              <img src={ln} alt="Linkedin" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="speaker-abstract">
                      <h2 className="speaker-abstract-title speaker-abstract-title-2">
                        "Web Accessibility for Devs"
                      </h2>
                      <ModalSpeaker
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        speakerAbstractText="Digital Accessibility has been gaining more and more traction over the last couple of years. You'll be briefed on why it's important that we start caring about accessibility on our products. Then we'll get a bit more technical and discuss the basics of implementing it as web developers. We'll be touching subjects such as HTML semantics, common mistakes with CSS and JS, WAI-ARIA, and React. We'll finish the talk with a demo based on a real world example going from not accessible at all to very accessible."
                        speakerAbstractTitle="Web Accessibility for Devs"
                      />
                      <button
                        className="speaker-button"
                        onClick={() => setModalShow(true)}
                      >
                        Show Abstract
                      </button>
                    </div>
                  </div>
                  <div className="speaker-flex speaker-flex-2">
                    <div className='speaker-info-wrap speaker-info-wrap-2'>
                      <div className="speaker-info">
                          <h2 className="speaker-name speaker-name-2">Agustina Chaer</h2>
                          <p className="speaker-pos speaker-pos-2">Tech Lead</p>
                          <img className="speaker-logo-img speaker-logo-img-2" src={Rootstrap} alt="Rootstrap" />
                      </div>
                      <div className="speaker-info">
                          <h2 className="speaker-name speaker-name-2">Elizabeth Lofredo</h2>
                          <p className="speaker-pos speaker-pos-2">Engineering Lead & Senior Frontend Developer</p>
                          <img className="speaker-logo-img speaker-logo-img-2" src={Rootstrap} alt="Rootstrap" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            <button className="hidebutton" onClick={hide} id="hidebutton">
              SHOW LESS
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Speakers;
