import React from 'react';
import moment, { duration } from 'moment';

export default class CountDown extends React.Component {
    state = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };

    setCountdown() {
        const futureDate = moment(this.props.futureDate);
        const today = moment();
        const clockDuration = duration(futureDate.diff(today));
        const days = Math.floor(clockDuration.asDays());
        const hours = clockDuration.hours();
        const minutes = clockDuration.minutes();
        const seconds = clockDuration.seconds();

        this.setState({
            days,
            hours,
            minutes,
            seconds
        })
    }

    componentDidMount() {
        this.setCountdown();

        this.interval = setInterval(() => {
            this.setCountdown();
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div className='timer_block'>
                {Object.keys(this.state).map((key, i) => (
                    <div className='countdown-number'>
                        <span className='days countdown-time'>
                            {this.state[key]}
                        </span>
                        <span className='countdown-text'>
                            {key}
                        </span>
                    </div>
                ))}
            </div>
        )
    }
}