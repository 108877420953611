import React from "react";
import "./enterprise.css";

const Enterprise = () => {
  return (
    <div className="enterprise" id="enterprise">
      <iframe className='iframe_enterprise'
        src="https://geekle.us/0e_react22/event=react2"
        width="100%"
        height="320"
        align="center"
        title='enterprise_frame'
      >
        Ваш браузер не поддерживает плавающие фреймы!
      </iframe>
    </div>
  );
};
export default Enterprise;
