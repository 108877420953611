import React from "react";
import "./header.css";
import avatar from "../../img/avatar.png";
import arrow from "../../img/Arrow.svg";
import video from "../../img/react.mp4";
import creative from "../../img/creative.png";
import { Container, Row, Col } from "react-bootstrap";
import Menu from "./Menu/Menu";
import { useState } from "react";
import {InlineShareButtons} from 'sharethis-reactjs';

const smoothScroll = (target) => {
  const { top } = target.getBoundingClientRect();
  window.scrollTo({
    top: top + window.pageYOffset,
    behavior: "smooth",
  });
};

const Header = (props) => {
  let [copySuccess, setCopySuccess] = useState('');

  function copyToClipboard(e) {
      e.preventDefault();
      const el = document.createElement('input');
      el.value = "https://events.geekle.us/react2/?utm_source=website&utm_medium=sharelink";
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      setCopySuccess('Copied!');
    };

  return (
    <div className="header" id="header">
      <div className="background-noise"></div>
      <div className="navbar">
        <div className="logo">
          <a href="https://events.geekle.us/react/">
            <img src={avatar} alt="Логотип" />
          </a>
        </div>
        <Menu
          speakers={props.speakers}
          workshops={props.workshops}
          //pc={props.pc}
          packages={props.packages}
          enterprise={props.enterprise}
        />
      </div>
      <div className="main-block">
        <Container>
          <div className="main_block-wrap">
            <div className="main_block-item">
              <div className="text">
                <h1>{"<React Global>"}</h1>
                <h2>Online Summit 2022</h2>
                <h4>April 20, 2022 - Junior Track</h4>
                <h4>April 21, 2022 - Senior Track</h4>
                
                <div className='main_btn_block'>
                  <button className='main_button' onClick={() => smoothScroll(props.packages.current)}>BUY TICKET</button>
                  <InlineShareButtons
                    className='main_button main_btn--share'
                    config={{
                      alignment: 'center',  // alignment of buttons (left, center, right)
                      color: 'black',      // set the color of buttons (social, white)
                      enabled: true,        // show/hide buttons (true, false)
                      font_size: 16,        // font size for the buttons
                      labels: 'cta',        // button labels (cta, counts, null)
                      language: 'en',       // which language to use (see LANGUAGES)
                      networks: [           // which networks to include (see SHARING NETWORKS)
                        'sharethis'
                      ],
                      padding: 12,          // padding within buttons (INTEGER)
                      radius: 0,            // the corner radius on each button (INTEGER)
                      size: 49,             // the size of each button (INTEGER)

                      // OPTIONAL PARAMETERS
                      url: 'https://events.geekle.us/react2/?utm_source=website&utm_medium=sharelink', // (defaults to current url)
                      description: 'Anything, that you have to know about React in 2022',       // (defaults to og:description or twitter:description)
                      title: 'React Global Online Summit 2022',            // (defaults to og:title or twitter:title)
                    }}
                  />
                </div>

                <div className="arrow">
                  <a href="#" onClick={() => smoothScroll(props.packages.current)}>
                    <img src={arrow} alt="arrow" />
                  </a>
                </div>
              </div>
            </div>
            <div className="main_block-item">
              <div className="video">
                <video
                  preload={"auto"}
                  autoPlay={true}
                  loop={true}
                  muted={"muted"}
                  playsInline
                >
                  <source src={video} type="video/mp4" />
                </video>
              </div>
              {/* <img className="main_creative" src={creative} alt="" /> */}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default Header;
