import React from "react";
import "./sponsors.css";
import { Container, Row, Col } from "react-bootstrap";
import aggrid from "../../img/sponsors/aggrid.png";

const Sponsors = () => {
  return (
    <div className="sponsors main_block">
      <Container>
        <Row>
          <Col>
            <div className="sponsors-title">
              <h2 className="title">{"<Sponsors>"}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="sponsors-photos">
              {/* <h3>Gold</h3>
              <div className="sponsors-photos-line">
                <div>
                  <a target='_blank' href="https://cloudinary.rocks/tbi">
                    <img className="sponsors-photos-img sponsors-photos-img--big" src={cloudinary} alt="cloudinary" />
                  </a>
                  <a target='_blank' href="https://betterme.world/career">
                    <img className="sponsors-photos-img sponsors-photos-img--big" src={Betterme} alt="Betterme" />
                  </a>
                </div>
              </div> */}
              <h3>Platinum</h3>
              <div className="sponsors-photos-line">
                <div>
                  <a target='_blank' href="https://www.ag-grid.com/">
                    <img className="sponsors-photos-img" src={aggrid} alt="" />
                  </a> 
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Sponsors;
