import React from "react";
import "./askCompany.css";

const AskCompany = () => {
  return (
    <div className="askCompany">
      <iframe className='iframe_askcompany'
        src="https://geekle.us/0a_react2?event=react2"
        width="100%"
        height="357"
        align="center"
        title='askCompany_frame'
      >
        Ваш браузер не поддерживает плавающие фреймы!
      </iframe>
    </div>
  );
};
export default AskCompany;
