import React, { useRef } from "react";
import "./App.css";
import Header from "./components/Header/Header.jsx";
import Info from "./components/Info/Info.jsx";
import Team from "./components/Team/Team.jsx";
import OurMission from "./components/OurMission/OurMission.jsx";
import WhatsNew from "./components/WhatsNew/WhatsNew.jsx";
import AskCompany from "./components/AskCompany/AskCompany.jsx";
import Enterprise from "./components/Enterprise/Enterprise.jsx";
import SpeakersFrom from "./components/SpeakersFrom/SpeakersFrom.jsx";
import FourKeys from "./components/FourKeys/FourKeys.jsx";
import Partners from "./components/Partners/Partners.jsx";
import Sponsors from "./components/Sponsors/Sponsors.jsx";
import Speakers from "./components/Speakers/Speakers.jsx";
import Packages from "./components/Packages/Packages.jsx";
import Agenda from "./components/Agenda/Agenda.jsx";
import Organizers from "./components/Organizers/Organizers.jsx";
import PC from "./components/PC/PC.jsx";
import Apply from "./components/Apply/Apply.jsx";
import Blocks from "./components/Blocks/Blocks.jsx";
import Workshops from "./components/Workshops/Workshops.jsx";
import Reviews from "./components/Reviews/reviews.jsx";
import News from "./components/News/news.jsx";
import Footer from "./components/Footer/Footer.jsx";
import Countdown from "./components/CountDown/CountDown.jsx";

const App = (props) => {
  window.location.href = 'https://events.geekle.us/react23/';

  const speakers = useRef();
  const workshops = useRef();
  const pc = useRef();
  const packages = useRef();
  const enterprise = useRef();
  return (
    <div className="App">
      <Header
        speakers={speakers}
        workshops={workshops}
        //pc={pc}
        packages={packages}
        enterprise={enterprise}
      />
      <Info />
      {/* <WhatsNew /> */}
      <Blocks />
      <div ref={speakers}>
        <SpeakersFrom />
      </div>
      <Speakers />
      {/* <FourKeys /> */}
      <div ref={packages}>
        <Packages />
      </div>
      <Agenda />
      <div ref={workshops}>
        <Workshops />
      </div>
      {/* <Team /> */}
      <Sponsors />
      {/* <Apply /> */}
      <AskCompany />
      <div ref={enterprise}>
        <Enterprise />
      </div>
      <Partners />
      <div ref={pc}>
        <PC />
      </div>
      <Reviews />
      {/* <News />  */}
      <Organizers />
      <OurMission />
      <Footer />
      <Countdown />
    </div>
  );
};

export default App;
