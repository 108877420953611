let ReviewsInfo = [
    {
      reviewsName: "Facundo Corradini",
      reviewsPosition: "Front-end Developer (Argentina)",
      reviewsText: `Thank you for creating such an awesome event! It was very warm
      and welcoming, and the technical quality was incredible. So
      many things to learn from the shared experiences! The platform
      is great, talks are top quality, and hosts are super
      welcoming. They knocked it out of the park with this one!`,
      reviewsAvatar: require("../img/Corradini.webp").default,
    },
    {
        reviewsName: "Felipe Tagle",
        reviewsPosition: "A3 Electric Mobility",
        reviewsText: `Amazing conference! The talks in senior track are really
        helpful and timely. I learned a lot and I am excited to try
        new things on React. Thank you for this wonderful opportunity!`,
        reviewsAvatar: require("../img/Tagle.webp").default,
    },
    {
      reviewsName: "Arnold Gandarillas Castillo",
      reviewsPosition: "Minka Inc.",
      reviewsText: `Excellent presentations and very knowledgeable speakers. Their
      answers to our questions are also informative and really help
      our understanding.`,
      reviewsAvatar: require("../img/Castillo.webp").default,
    },
    {
        reviewsName: "Alberto Lema",
        reviewsPosition: "LEMAC",
        reviewsText: `It was a great opportunity for me and also my first time and I
        enjoyed it. I'm looking forward to more in the near future.`,
        reviewsAvatar: require("../img/Lema.webp").default,
    },
  ];
  
  export default ReviewsInfo;
  