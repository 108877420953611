import React from "react";
import "./footer.css";
import { Container, Row, Col } from "react-bootstrap";
import avatar from "../../img/avatar.png";
import fb from "../../img/fb.svg";
import ln from "../../img/ln.svg";
import tw from "../../img/tw.svg";

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col>
            <div className="logo">
              <a href="https://events.geekle.us/react/">
                <img src={avatar} alt="Логотип" />
              </a>
            </div>
            <div className="social">
              <a
                href="https://www.facebook.com/geekleclubs/"
                target={"_blank"}
                rel="noreferrer"
                className="fb"
              >
                <img src={fb} alt="FaceBook" />
              </a>
              <a
                href="https://www.linkedin.com/company/geekle-us/"
                target={"_blank"}
                rel="noreferrer"
                className="ln"
              >
                <img src={ln} alt="Linkedin" />
              </a>
              <a
                href="https://twitter.com/GeekleOfficial"
                target={"_blank"}
                rel="noreferrer"
                className="tw"
              >
                <img src={tw} alt="Twitter" />
              </a>
            </div>
          </Col>
          <Col>
            <div className="footer-text">
              <p>Geekle Corp.</p>
              <p>910 Foulk Road, Suite 201</p>
              <p>Wilmington, DE</p>
              <p>19803, USA</p>
            </div>
          </Col>
          <Col>
            <div className="footer-links">
              <a href="tel:+16505342075">+1 650 534 2075</a>
              <a href="mailto:info@geekle.us">info@geekle.us</a>
            </div>
          </Col>
          <Col>
            <div className="footer-links-2">
              <a href="https://react.geekle.us/code-of-conduct">
                Code Of Conduct
              </a>
              <a href="https://react.geekle.us/privacy-policy">
                Privacy Policy
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Footer;
