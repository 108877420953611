let speakerInfo = [
  {
    speakerPhoto: require("../img/Speakers/Ebenezer Don.png").default,
    speakerName: "Ebenezer Don",
    speakerPosition: "Developer Advocate",
    speakerTrack: "Junior",
    speakerLinkedinPath: "https://linkedin.com/in/ebenezerdon",
    speakerTwitterPath: "https://twitter.com/ebenezerDN",
    speakerGithubPath: "https://github.com/ebenezerdon",
    speakerYBPath: "https://youtube.com/ebenezerdon",
    speakerAbstractTitle: `Dexie.js For Offline Data Storage In React Apps`,
    speakerAbstractText: `So many things can go wrong when you don’t properly handle data storage in the browser. For example, the widely used localStorage can render your web app vulnerable to XSS attacks if not used properly. What if you want to store more than text-data, like images, music or videos? What if you want to send an advanced query to your browser storage, find or update by ID, or even exceed the 5mb localStorage limit? It becomes very difficult to make your web app work like a real app with all of these limitations.

    Did you know that the browser has an inbuilt non-relational database named IndexedDb? In this talk, I’ll teach you how to use Dexie.js, the minimalistic wrapper for IndexedDb to store and handle structured data in the browser.`,
    speakerLogo: require("../img/Speakers/JetBrains.png").default
  },
  // {
  //   speakerPhoto: require("../img/Speakers/Naz Delam.png").default,
  //   speakerName: "Naz Delam",
  //   speakerPosition: "Software Engineering Manager",
  //   speakerTrack: "Senior",
  //   speakerWebsitePath: "https://naz.dev",
  //   speakerTwitterPath: "https://twitter.com/NazDelam",
  //   speakerLinkedinPath: "https://www.linkedin.com/in/nazdelam/",
  //   speakerAbstractTitle: `Leading without Burning out`,
  //   speakerAbstractText: `As you grow in your career and become a tech lead or choose to enter the realm of management, your responsibilities grow, and your time to focus shrinks hence you will be more prone to burnout. 
  //   It is crucial to learn how to manage multiple concurrent tasks at the same time. In this talk, I will walk you through a prioritization framework that could tremendously help you to identify, prioritize and delegate to effectively enable you to manage your daily work.`,
  //   speakerLogo: require("../img/Speakers/Netflix.png").default
  // },
  {
    speakerPhoto: require("../img/Speakers/Roei Berkovich.png").default,
    speakerName: "Roei Berkovich",
    speakerPosition: "Software Team Lead",
    speakerTrack: "Junior",
    speakerLinkedinPath: "https://www.linkedin.com/in/roeiberkovich/",
    speakerAbstractTitle: `The power of profiling`,
    speakerAbstractText: `In this session we will learn how to use React DevTools Profiler plugin to profile a real application
    We will learn how to use these tools to identify rendering problems, performance issues and make user experience really smooth. We are going to explore different React optimization techniques that allow us to avoid unnecessary re-renders or doing heavy computations on every render.`,
    speakerLogo: require("../img/Speakers/AppsFlyer.png").default
  },
  {
    speakerPhoto: require("../img/Speakers/Sean McPherson.png").default,
    speakerName: "Sean McPherson",
    speakerPosition: "Software Engineer",
    speakerTrack: "Junior",
    speakerGithubPath: "https://github.com/seanmcp",
    speakerAbstractTitle: `React Bug Catcher`,
    speakerAbstractText: `Encountering bugs is a common component of building applications in React. The better you are able to identify them, the faster you can fix them and continue building. In this talk, we will learn how to use the React DevTools and Chrome DevTools to explore common bugs in React apps. You can follow along with the talk by debugging the live, Pokémon-themed application.`,
    speakerLogo: require("../img/Speakers/Khan Academy.png").default
  },
  {
    speakerPhoto: require("../img/Speakers/Sendil Kumar.png").default,
    speakerName: "Sendil Kumar",
    speakerPosition: "Engineering Manager at Uber",
    speakerTrack: "Senior",
    speakerTwitterPath: "https://twitter.com/sendilkumarn",
    speakerAbstractTitle: `Batching, Suspense, and server components `,
    speakerAbstractText: `React 18 comes up batching and server rendering with suspense. Batching makes sure the components rendering are batched and stops unwanted re-rendering of the components. Server rendering with suspense on the other hand makes your application more interactive. Finally Server components promises fast performance and better user experience. Let us explore these new features in React and how we can benefit from them.`,
  },
  {
    speakerPhoto: require("../img/Speakers/Binh Nguyen.png").default,
    speakerName: "Binh Nguyen",
    speakerPosition: "Creative Technologist at Accenture",
    speakerTrack: "Junior",
    speakerLinkedinPath: "https://www.linkedin.com/in/binhnguyen710/",
    speakerAbstractTitle: `An introduction to Design Language System (DLS)`,
    speakerAbstractText: `I'll give an introduction about DLS (what is it, the benefits of using it), what components are typically included and how to have a DLS with Storybook and React.`,
    speakerLogo: require("../img/Speakers/Accenture.png").default
  },
  {
    speakerPhoto: require("../img/Speakers/Feross Aboukhadijeh.png").default,
    speakerName: "Feross Aboukhadijeh",
    speakerPosition: "Founder & CEO",
    speakerTrack: "Junior",
    speakerTwitterPath: "https://twitter.com/feross",
    speakerWebsitePath: "https://feross.org",
    speakerWebsitePath2: "https://socket.dev",
    speakerAbstractTitle: `It's a Jungle Out There! – Open Source Supply Chain Attacks`,
    speakerAbstractText: `Software supply chain attacks have exploded in the past year, and open source components are increasingly used as a vector. Come hear some of the wilder stories of malware in JavaScript packages, and what you can do to protect your apps.`,
    speakerLogo: require("../img/Speakers/Socket.png").default
  },
];

export default speakerInfo;
