let NewsInfo = [
  {
    id: 1,
    newsTitle: `"How I got multiple FAANG offers in Europe"`,
    newsName: "Andrey Goncharov",
    newsPosition: "Software Engineer",
    newsAvatar: require("../img/Goncharov.png").default,
    newsLogo: require("../img/Goncharov_Facebook.png").default,
    newsLink: "https://blog.goncharov.page/how-i-got-multiple-faang-offers-in-europe",
  },
  {
    id: 2,
    newsTitle: `"Why Women Don’t Pursue Careers in Software Engineering"`,
    newsName: "Anna Carey",
    newsPosition: "Software Engineer",
    newsAvatar: require("../img/Carey.png").default,
    newsLogo: require("../img/Artsy.png").default,
    newsLink: "https://betterprogramming.pub/why-women-dont-pursue-software-engineering-17d0dead24e9",
  },
  {
    id: 3,
    newsTitle: `"Automate Social Images with Next.js and Puppeteer"`,
    newsName: "Facundo Giuliani",
    newsPosition: "Developer Relations Engineer",
    newsAvatar: require("../img/Giuliani.png").default,
    newsLogo: require("../img/Storyblok.png").default,
    newsLink: "https://fgiuliani.com/posts/automate-social-images-next-puppeteer/",
  },
  {
    id: 4,
    newsTitle: `"useEffect Pet Peeve: Side Effects, Not Initialization"`,
    newsName: "Josh ‌Goldberg",
    newsPosition: "Staff Software Engineer",
    newsAvatar: require("../img/Goldberg.png").default,
    newsLogo: require("../img/Codecademy.png").default,
    newsLink: "https://blog.joshuakgoldberg.com/use-effect-pet-peeve/",
  },
];

export default NewsInfo;
