import React from "react";
import "./ourMission.css";
import { Container, Row, Col } from "react-bootstrap";
import Team from "../../img/Team.jpg";

const OurMission = () => {
  return (
    <div className="ourMission">
      <Container>
        <Row>
          <Col>
            <div className="ourMission-title">
              <h2 className="title">{"<Our Mission>"}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ourMission-photo">
              <img src={Team} alt="OurMission" />
            </div>
          </Col>
          <Col>
            <div className="ourMission-text">
              <p>
                Offline events are gone for some time, but the tech world
                doesn't slow down.
                <br />
                React JS community needs the platform to share the latest
                updates and use cases to improve coding skills and get
                architectural insights. We aim to provide an online event for
                React JS geeks from all over the world to share what's new in
                domain.
              </p>
              <p>
                Geekle has the unique experience to gather huge tech summits
                with 11'000+ attendees with 95% of free users registered in
                different tech domains. Now we hope to make something world
                never seen before for the React JS Community.
                <br />
                See ya'll!
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default OurMission;
