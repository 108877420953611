let SpeakersFromInfo = [
    {
        speakersFromLogo: require("../img/SF/Accenture.png").default,
        speakersFromAlt: 'Accenture',
    },
    {
        speakersFromLogo: require("../img/SF/Adobe.png").default,
        speakersFromAlt: 'Adobe',
    },
    {
        speakersFromLogo: require("../img/SF/Netflix.png").default,
        speakersFromAlt: 'Netflix',
    },
    {
        speakersFromLogo: require("../img/SF/AppsFlyer.png").default,
        speakersFromAlt: 'AppsFlyer',
    },
    {
        speakersFromLogo: require("../img/SF/KhanAcademy.png").default,
        speakersFromAlt: 'KhanAcademy',
    },
    {
        speakersFromLogo: require("../img/SF/Hello.png").default,
        speakersFromAlt: 'Hello',
    },
    {
        speakersFromLogo: require("../img/SF/Socket.png").default,
        speakersFromAlt: 'Socket',
    },
    {
        speakersFromLogo: require("../img/SF/RIOTS.png").default,
        speakersFromAlt: 'RIOTS',
    },
    {
        speakersFromLogo: require("../img/SF/Vercel.png").default,
        speakersFromAlt: 'Vercel',
    },
    {
        speakersFromLogo: require("../img/SF/JetBrains.png").default,
        speakersFromAlt: 'JetBrains',
    },
  ];
  
  export default SpeakersFromInfo;
  