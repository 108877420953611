import React from "react";
import "./info.css";
import Marquee from 'react-double-marquee';

const Info = () => {
  return (
    <>
    <div className="audience">
      <div className='audience_wrap container--full' style={{
          width: '100%',
          whiteSpace: 'nowrap',
      }}>
        <Marquee delay={0} childMargin={0}>
            <span className="green"> 10 000+ Registrations</span><span>10 000+ Registrations</span><span className="green">10 000+ Registrations</span><span>10 000+ Registrations</span><span className="green"> 10 000+ Registrations</span><span>10 000+ Registrations</span><span className="green">10 000+ Registrations</span><span>10 000+ Registrations</span><span className="green"> 10 000+ Registrations</span><span>10 000+ Registrations</span><span className="green">10 000+ Registrations</span><span>10 000+ Registrations</span><span className="green"> 10 000+ Registrations</span><span>10 000+ Registrations</span><span className="green">10 000+ Registrations</span><span>10 000+ Registrations</span>
        </Marquee>
        <Marquee delay={0} childMargin={0} direction='left'>
            <span className="green">Speakers 30+</span><span>Speakers 30+</span><span className="green">Speakers 30+</span><span>Speakers 30+</span><span className="green">Speakers 30+</span><span>Speakers 30+</span><span className="green">Speakers 30+</span><span>Speakers 30+</span><span className="green">Speakers 30+</span><span>Speakers 30+</span><span className="green">Speakers 30+</span><span>Speakers 30+</span><span className="green">Speakers 30+</span><span>Speakers 30+</span><span className="green">Speakers 30+</span><span>Speakers 30+</span><span className="green">Speakers 30+</span><span>Speakers 30+</span><span className="green">Speakers 30+</span><span>Speakers 30+</span><span className="green">Speakers 30+</span><span>Speakers 30+</span><span className="green">Speakers 30+</span><span>Speakers 30+</span>
        </Marquee>
        <Marquee delay={0} childMargin={0}>
            <span className="green"> 15+ Real use-cases</span><span>15+ Real use-cases</span><span className="green"> 15+ Real use-cases</span><span>15+ Real use-cases</span><span className="green"> 15+ Real use-cases</span><span>15+ Real use-cases</span><span className="green"> 15+ Real use-cases</span><span>15+ Real use-cases</span><span className="green"> 15+ Real use-cases</span><span>15+ Real use-cases</span><span className="green"> 15+ Real use-cases</span><span>15+ Real use-cases</span><span className="green"> 15+ Real use-cases</span><span>15+ Real use-cases</span><span className="green"> 15+ Real use-cases</span><span>15+ Real use-cases</span><span className="green"> 15+ Real use-cases</span><span>15+ Real use-cases</span><span className="green"> 15+ Real use-cases</span><span>15+ Real use-cases</span>
        </Marquee>
      </div>
    </div>
    </>
  );
};
export default Info;
