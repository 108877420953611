import React from "react";
import "./speakersFrom.css";
import { Container, Row, Col } from "react-bootstrap";
import SpeakersFromInfofrom from "../../store/SpeakersFromInfo";
import SpeakersFromChild from './speakersFrom/speakersFrom.jsx'

const SpeakersFrom = () => {
  let speakersFromElements = SpeakersFromInfofrom.map((speakersFromItem, i) => {
    return <SpeakersFromChild key={`speakers-from-item${i}`} speakersFrom={speakersFromItem} />;
  });
  return (
    <div className="speakersFrom">
      <Container>
        <Row>
          <Col>
            <div className="speakersFrom-title">
              <h2 className="title">{"<Speakers from>"}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="speakersFrom-photos">
                <div className='speakersfrom-logo-block'>{speakersFromElements}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default SpeakersFrom;
