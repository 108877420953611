import React from "react";
import "./blocks.css";
import { Container, Row, Col } from "react-bootstrap";
import React18 from "../../img/fourKeys/React18.png";
import Security from "../../img/fourKeys/Security.png";
import Testing from "../../img/fourKeys/Testing.png";
import Web3 from "../../img/fourKeys/Web3.png";

const Blocks = () => {
  return (
    <div className="blocks">
      <div className="block-1">
        <Container>
          <Row>
            <Col>
              <div className="block">
                <div className="block-item">
                  <h3>Security in React - how to protect your project from malware in JavaScript packages.</h3>
                </div>
                <div className="block-item">
                  <img src={Security} alt="Photo" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="block-2">
        <Container>
          <Row>
            <Col>
              <div className="block">
                <div className="block-item block-item-mob">
                  <h3>Testing Complex React Applications - advanced testing strategies explained.</h3>
                </div>
                <div className="block-item">
                  <img src={Testing} alt="Photo" />
                </div>
                <div className="block-item block-item-hide">
                  <h3>Testing Complex React Applications - advanced testing strategies explained.</h3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="block-3">
        <Container>
          <Row>
            <Col>
              <div className="block">
                <div className="block-item">
                  <h3>Learn how to Interact with Web3 from your React Application</h3>
                </div>
                <div className="block-item">
                  <img src={Web3} alt="Photo" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="block-4">
        <Container>
          <Row>
            <Col>
              <div className="block">
                <div className="block-item block-item-mob">
                  <h3>React 18 - How Batching, Suspense, and Server Components change our web apps.</h3>
                </div>
                <div className="block-item">
                  <img src={React18} alt="Photo" />
                </div>
                <div className="block-item block-item-hide">
                  <h3>React 18 - How Batching, Suspense, and Server Components change our web apps.</h3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default Blocks;
