import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import $ from "jquery";


// const { JSDOM } = require( "jsdom" );
// const { window } = new JSDOM( "" );
// const $ = require( "jquery" )( window );

$(document).ready(function () {
  $("a").each(function () {
      var href = $(this).attr("href");
      var search = "?" + window.location.search.split("&").filter(val => val.replace('?', '').indexOf('s_') !== 0).join("&").replace('?', '');
      if (search !== "?" && href != undefined && href.includes("events.geekle.us") && !href.includes("#")) {
          if (!href.includes("?")) {
              $(this).attr("href", href + search);
          } else {
              $(this).attr("href", href + search.replace("?", "&"));
          }
      }
  });
});





ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
