import "./Menu.css";
import hamburger from "../../../img/hamburger.svg";
// import close from "../../../img/close.svg";
// import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import OpenMenu from "./OpenMenu/OpenMenu";

const Menu = (props) => {
  const [showText, setShowText] = useState(false);
  const onClick = () => setShowText(true);
  const onClickclose = () => setShowText(false);
  const smoothScroll = (target) => {
    const { top } = target.getBoundingClientRect();
    window.scrollTo({
      top: top + window.pageYOffset,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="mobile-menu">
        <div>
          <img src={hamburger} onClick={onClick} className="hamburgerMenu" />
        </div>
        <div>
          {showText ? (
            <OpenMenu
              close={onClickclose}
              speakers={props.speakers}
              workshops={props.workshops}
              //pc={props.pc}
              packages={props.packages}
              enterprise={props.enterprise}
            />
          ) : null}
        </div>
      </div>
      <div className="menu">
        <ul>
          <li>
            <a href="#" onClick={() => smoothScroll(props.speakers.current)}>
              Speakers
            </a>
          </li>
          <li>
            <a href="#" onClick={() => smoothScroll(props.workshops.current)}>
              Workshops
            </a>
          </li>
          {/* <li>
            <a href="#" onClick={() => smoothScroll(props.pc.current)}>
              Committee
            </a>
          </li> */}
          <li>
            <a href="#" onClick={() => smoothScroll(props.packages.current)}>
              Tickets
            </a>
          </li>
          <li>
            <a href="#" onClick={() => smoothScroll(props.enterprise.current)}>
              Enterprise
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
export default Menu;
