import './agenda.css'
import React, { useState } from "react";
import ln from "../../../img/ln.svg";
import gh from "../../../img/github_2.svg";
import tw from "../../../img/twitter.svg";
import { Container, Row, Col } from "react-bootstrap";
import ModalAgenda from "../ModalAgenda/ModalAgenda";

const Agenda = (props) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <Row>
        <div className="agenda_item">
            <div className="agenda_item_first">
                <p className='agenda_time'>
                  {props.agenda.agendaTime}
                </p>
                <p className="agenda_title">
                {props.agenda.agendaTitle}
                </p>
                <img className="agenda_logo" src={props.agenda.agendaLogo} alt="logo" />
            </div>
            <div className="agenda_item_second">
              <div className='agenda_speaker'>
                <div className="agenda_info">
                  <img className="agenda_photo" src={props.agenda.agendaAvatar} alt="photo " />
                  <div className='agenda_speaker_info'>
                    <p className="agenda_name">{props.agenda.agendaName}</p>
                    <p className="agenda_position">{props.agenda.agendaPosition}</p>
                  </div>
                </div>
                <div className='agenda_social'>
                  <a
                    href={props.agenda.agendaLinkedinPath}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {props.agenda.agendaLinkedinPath ? (
                      <img src={ln} alt="Linkedin" />
                    ) : undefined}
                  </a>
                  <a
                    href={props.agenda.agendaGithubPath}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {props.agenda.agendaGithubPath ? (
                      <img src={gh} alt="Github" />
                    ) : undefined}
                  </a>
                  <a
                    href={props.agenda.agendaTwitterPath}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {props.agenda.agendaTwitterPath ? (
                      <img src={tw} alt="Twitter" />
                    ) : undefined}
                  </a>
                </div>
              </div>
              <div className='agenda_link_wrap'>
                <ModalAgenda
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  agendaAbstractText={props.agenda.agendaAbstractText}
                  agendaTitle={props.agenda.agendaTitle}
                />
                <button
                  className="speaker-button"
                  onClick={() => setModalShow(true)}
                >
                  Show Abstract
                </button>
              </div>
            </div>
        </div>
    </Row>
  );
};

export default Agenda;
