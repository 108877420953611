import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const SpeakersFrom = (props) => {
  return (
    <img src={props.speakersFrom.speakersFromLogo} alt={props.speakersFrom.speakersFromAlt} />
  );
};

export default SpeakersFrom;
