let AgendaInfo = [
  {
    id: 1,
    agendaTime: `10:10`,
    agendaTitle: `"An introduction to Design Language System (DLS)"`,
    agendaName: "Binh Nguyen",
    agendaPosition: "Creative Technologist",
    agendaLinkedinPath: "https://www.linkedin.com/in/binhnguyen710/",
    agendaAbstractTitle: 'An introduction to Design Language System (DLS)',
    agendaAbstractText: `I'll give an introduction about DLS (what is it, the benefits of using it), what components are typically included and how to have a DLS with Storybook and React.`,
    agendaLogo: require("../img/Speakers/Accenture.png").default,
    agendaAvatar: require("../img/Speakers/Binh Nguyen.png").default,
  },
  {
    id: 2,
    agendaTime: `10:50`,
    agendaTitle: `"Dexie.js For Offline Data Storage In React Appsn"`,
    agendaName: "Ebenezer Don",
    agendaPosition: "Developer Advocate",
    agendaLinkedinPath: "https://linkedin.com/in/ebenezerdon",
    agendaTwitterPath: "https://twitter.com/ebenezerDN",
    agendaGithubPath: "https://github.com/ebenezerdon",
    agendaAbstractTitle: "Dexie.js For Offline Data Storage In React Apps",
    agendaAbstractText: `So many things can go wrong when you don’t properly handle data storage in the browser. For example, the widely used localStorage can render your web app vulnerable to XSS attacks if not used properly. What if you want to store more than text-data, like images, music or videos? What if you want to send an advanced query to your browser storage, find or update by ID, or even exceed the 5mb localStorage limit? It becomes very difficult to make your web app work like a real app with all of these limitations.

    Did you know that the browser has an inbuilt non-relational database named IndexedDb? In this talk, I’ll teach you how to use Dexie.js, the minimalistic wrapper for IndexedDb to store and handle structured data in the browser.`,
    agendaLogo: require("../img/Speakers/JetBrains.png").default,
    agendaAvatar: require("../img/Speakers/Ebenezer Don.png").default,
  },
  {
    id: 3,
    agendaTime: `11:30`,
    agendaAvatar: require("../img/Speakers/Ridhal.png").default,
    agendaName: "Sumit Ridhal",
    agendaPosition: "Senior Software Engineer",
    agendaLinkedinPath: "https://ca.linkedin.com/in/elad-tzemach-020a16a4",
    agendaTitle: '"Introduction to Generative Art"',
    agendaAbstractTitle: "Introduction to Generative Art",
    agendaAbstractText: `This is introductory talk to start with creative coding.
    Fundamental of Generative Art will cover 
    a. What is Generative Art? b. Fundamental Elements c. Control Functions e.g random vs noise d. Fractals
    The final result will be building a kaleidoscope app in react.
    And will expose the controls to dictate the final result. This has less to do with React but this can serves as a base to next chapter building: 
    a. 3D scenes in React application b. AR/VR app using React`,
    agendaLogo: require("../img/Speakers/RedHat.png").default
  },
];

export default AgendaInfo;
