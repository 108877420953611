import React from "react";
import Workshop from "./Workshop/Workshop";
import "./Workshops.css";
import WorkshopInfofrom from "../../store/WorkshopInfo";
import { Container, Row, Col } from "react-bootstrap";

const Workshops = (props) => {
  let workshopElements = WorkshopInfofrom.map((workshopItem) => {
    return <Workshop workshop={workshopItem} />;
  });
  return (
    <>
      <div className="Workshops">
        <Container>
          <Row>
            <Col>
              <div className="workshops-title">
                <h2 className="title">{"<Workshops>"}</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="workshopmedia">{workshopElements}</div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Workshops;
