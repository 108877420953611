import React from "react";
import "./Agenda.css";
import { Container, Row, Col } from "react-bootstrap";
import AgendaInfofrom from "../../store/AgendaInfo";
import AgendaChild from './agenda/agenda.jsx'

const Agenda = () => {
  let agendaElements = AgendaInfofrom.map((agendaItem, i) => {
    return <AgendaChild key={`agenda-item=${i}`} agenda={agendaItem} />;
  });
  return (
    <div className="agenda main_block">
      <Container>
        <Row>
          <Col>
            <div className="agenda_title_block">
              <h2 className="title">{"<Agenda>"}</h2>
            </div>
          </Col>
        </Row>
        <div className="agendamedia">{agendaElements}</div>
        <a
          href="https://geekle.us/schedule/react2"
          className='showbutton'
          target='_blank'>
            SHOW FULL AGENDA</a>
      </Container>
    </div>
  );
};
export default Agenda;
