import React, { useState } from "react";
import "./speaker.css";
import ln from "../../../img/ln.svg";
import gh from "../../../img/github_2.svg";
import tw from "../../../img/twitter.svg";
import website from "../../../img/website.svg";
import youtube from "../../../img/youtube.svg";
import ModalSpeaker from "../ModalSpeaker/ModalSpeaker";

const Speaker = (props) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="speaker-main">
      <div className="speaker">
        <div className="speaker_plashka">{props.speakerTrack}</div>
        <div className="speaker-flex">
          <div className="speaker-photo">
            <img
              className="speaker-photo-img"
              src={props.speakerPhoto}
              alt="Speaker"
            />
            <div className="speaker-social">
              <a
                href={props.speakerLinkedinPath}
                target={"_blank"}
                rel="noreferrer"
              >
                {props.speakerLinkedinPath ? (
                  <img src={ln} alt="" />
                ) : undefined}
              </a>
              <a
                href={props.speakerGithubPath}
                target={"_blank"}
                rel="noreferrer"
              >
                {props.speakerGithubPath ? (
                  <img src={gh} alt="" />
                ) : undefined}
              </a>
              <a
                href={props.speakerTwitterPath}
                target={"_blank"}
                rel="noreferrer"
              >
                {props.speakerTwitterPath ? (
                  <img src={tw} alt="" />
                ) : undefined}
              </a>
              <a
                href={props.speakerWebsitePath}
                target={"_blank"}
                rel="noreferrer"
              >
                {props.speakerWebsitePath ? (
                  <img src={website} alt="" />
                ) : undefined}
              </a>
              <a
                href={props.speakerWebsitePath2}
                target={"_blank"}
                rel="noreferrer"
              >
                {props.speakerWebsitePath2 ? (
                  <img src={website} alt="" />
                ) : undefined}
              </a>
              <a
                href={props.speakerYBPath}
                target={"_blank"}
                rel="noreferrer"
              >
                {props.speakerYBPath ? (
                  <img src={youtube} alt="" />
                ) : undefined}
              </a>
            </div>
          </div>
          <div className="speaker-abstract">
            <h2 className="speaker-abstract-title">
              "{props.speakerAbstractTitle}"
            </h2>
            <ModalSpeaker
              show={modalShow}
              onHide={() => setModalShow(false)}
              speakerAbstractText={props.speakerAbstractText}
              speakerAbstractTitle={props.speakerAbstractTitle}
            />
            <button
              className="speaker-button"
              onClick={() => setModalShow(true)}
            >
              Show Abstract
            </button>
          </div>
        </div>
        <div className="speaker-flex speaker-flex-2">
          <div className="speaker-info">
            <h2 className="speaker-name">{props.speakerName}</h2>
            <p className="speaker-pos">{props.speakerPosition}</p>
            {props.speakerLogo && <img
              className="speaker-logo-img"
              src={props.speakerLogo}
              alt="Aws"
            />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Speaker;
