import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./pc.css";
import Goncharov from "../../img/PC/Andrey Goncharov.png";
import Meta from "../../img/PC/Meta.png";
import m4dz from "../../img/PC/m4dz.png";
import RIOTS from "../../img/PC/RIOTS.png";
import Don from "../../img/PC/Ebenezer Don.png";
import JetBrains from "../../img/PC/JetBrains.png";
import Golberg from "../../img/PC/‌Josh ‌Goldberg.png";
import Yosef from "../../img/PC/Liad Yosef.png";
import Duda from "../../img/PC/Duda.png";
import Heydari from "../../img/PC/Milad Heydari.png";
import Hastnama from "../../img/PC/Hastnama Creative Solutions.png";

const PС = (props) => {
  const state = {
    person: [
      {
        photo: Goncharov,
        name: "Andrey Goncharov",
        position: "Senior Software Engineer",
        linkedin: "https://www.linkedin.com/in/aigoncharov/",
        twitter: "https://twitter.com/ai_goncharov",
        logo: Meta,
      },
      {
        photo: m4dz,
        name: "m4dz",
        position: "Developer Advocate",
        linkedin: "https://www.linkedin.com/in/m4d-z/",
        twitter: "https://twitter.com/m4d_z",
        logo: RIOTS,
      },
      {
        photo: Don,
        name: "Ebenezer	Don",
        position: "Developer Advocate",
        linkedin: "https://www.linkedin.com/in/ebenezerdon/",
        twitter: "https://twitter.com/ebenezerDN",
        logo: JetBrains,
      },
      {
        photo: Golberg,
        name: "Josh ‌Goldberg",
        position: "Open Source Developer",
        linkedin: "https://www.linkedin.com/in/joshuakgoldbergcodes/",
        twitter: "https://twitter.com/JoshuaKGoldberg",
      },
      {
        photo: Yosef,
        name: "Liad Yosef",
        position: "Frontend Architect",
        linkedin: "https://www.linkedin.com/in/liadyosef/",
        logo: Duda,
      },
      {
        photo: Heydari,
        name: "Milad Heydari",
        position: "Tech-Lead & Co-founder",
        linkedin: "https://www.linkedin.com/in/miladheydari/",
        logo: Hastnama,
      },
    ],
  };

  const renderPerson = (item, i) => {
    return (
      <div className="pc_item" key={`pc_item-${i}`}>
        <img
          src={item.photo}
          className="pc_item_photo"
          alt="Programm Committee"
        />
        <div className="pc_info">
          <p className="pc_item_name">{item.name}</p>
          <p className="pc_item_position">{item.position}</p>
          <div className="links_block">
            {item.linkedin ? (
              <a
                href={item.linkedin}
                target="_blank"
                rel="noreferrer"
                className="link pc_link pc_link--ln"
              ></a>
            ) : undefined}
            {item.twitter ? (
              <a
                href={item.twitter}
                target="_blank"
                rel="noreferrer"
                className="link pc_link pc_link--twitter"
              ></a>
            ) : undefined}
          </div>
        </div>
        {item.logo && <img
          src={item.logo}
          className="pc_item_logo"
          alt="Programm Committee"
        />}
      </div>
    );
  };

  return (
    <div className="pc_wrap main_block" id="pc">
      <Container>
        <Row>
          <Col>
            <div className="organizers_title">
              <h2 className="title">{"<PROGRAM COMMITTEE>"}</h2>
            </div>
          </Col>
        </Row>
        <div className="pc">
          {state.person.map((item, i) => renderPerson(item, i))}
        </div>
      </Container>
    </div>
  );
};

export default PС;
