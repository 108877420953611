import "./ModalSpeaker.css";
import { Container } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";
const ModalSpeaker = (props) => {
  return (
    <Modal {...props}>
      <Modal.Header className="modal-header">
        <h1>{props.speakerAbstractTitle}</h1>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <p>{props.speakerAbstractText}</p>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSpeaker;
