let speakerInfoHide = [
  {
    speakerPhoto: require("../img/Speakers/Josh Goldberg.png").default,
    speakerName: "Josh Goldberg",
    speakerPosition: "Open Source Developer",
    speakerTrack: "Junior",
    speakerTwitterPath: "https://twitter.com/JoshuaKGoldberg",
    speakerGithubPath: "https://github.com/JoshuaKGoldberg",
    speakerAbstractTitle: `Props, Mocks, and Context: Unit Testing Heavy Components`,
    speakerAbstractText: `Testing React components is important for enforcing (and documenting) proper application behavior, but when your components rely on dependencies it can be hard to know what the right way to test them. Do you pass in dependencies as props? Mock them out with jest.mock or similar? Plop them into a React context? So many choices! This talk will cover all their pros and cons along with tips and tricks for reliable component testing — no matter how dependency-heavy your app is.`,
  },
  {
    speakerPhoto: require("../img/Speakers/Nico Martin.png").default,
    speakerName: "Nico Martin",
    speakerPosition: "Frontend Developer",
    speakerTrack: "Junior",
    speakerTwitterPath: "https://twitter.com/nic_o_martin",
    speakerGithubPath: "https://github.com/nico-martin",
    speakerWebsitePath: "https://nico.dev/",
    speakerAbstractTitle: `WebBluetooth - the missing link`,
    speakerAbstractText: `With Progressive Web Apps and the Project-FUGU, the web is increasingly becoming a full-fledged platform for complex applications.
    But what has been missing for a long time is the possibility to communicate with devices in the periphery. With WebBluetooth, this gap has now been closed, which makes it possible to interact with light bulbs, fitness trackers or even DIY toy cars directly via a react application. Of course, peppered with tangible examples and ready to use react hooks. But those who are used to open and transparent transmission protocols will inevitably come up against a few hurdles - the story of a frontend developer dipping his toes into the IOT world.`,
    speakerLogo: require("../img/Speakers/Hello.png").default
  },
  {
    speakerPhoto: require("../img/Speakers/Tyler Hawkins.png").default,
    speakerName: "Tyler Hawkins",
    speakerPosition: "Senior Software Engineer",
    speakerTrack: "Senior",
    speakerLinkedinPath: "https://www.linkedin.com/in/tyler-hawkins-2b147513b/",
    speakerTwitterPath: "https://twitter.com/thawkin3",
    speakerGithubPath: "https://github.com/thawkin3",
    speakerWebsitePath: "https://medium.com/@thawkin3",
    speakerAbstractTitle: `Why You Need a Design System (and How to Do It Right)`,
    speakerAbstractText: `Your frontend application needs a design system. If you want to provide a consistent UX, ship features faster, make re-branding changes more easily, and free up your time to focus on hard UX problems, design systems are for you.
    The question is, should you use a third-party design system or build your own?
    I’ve contributed to design systems at my last three companies, and I’d like to share what’s worked and what hasn’t. Overall, I’ve found that building a design system is as much of an organizational challenge as it is a technical challenge.`,
    speakerLogo: require("../img/Speakers/Adobe.png").default
  },
    {
      speakerPhoto: require("../img/Speakers/Hassan El Mghari.png").default,
      speakerName: "Hassan El Mghari",
      speakerPosition: "Developer Advocate",
      speakerTrack: "Senior",
      speakerTwitterPath: "https://twitter.com/nutlope",
      speakerAbstractTitle: `Building ecommerce applications with Swell and Next.js`,
      speakerAbstractText: `In this talk, we'll go over how to build a performant ecommerce web application using Swell, Next.js with on-demand ISR, PlanetScale, and Vercel. We'll introduce these technologies separately then dive into some live coding and a code walkthrough of a full ecommerce application.`,
      speakerLogo: require("../img/Speakers/Vercel.png").default
    },
    {
      speakerPhoto: require("../img/Speakers/m4dz.png").default,
      speakerName: "m4dz 🎙🥑",
      speakerPosition: "Principal Developer Advocate",
      speakerTrack: "Senior",
      speakerTwitterPath: "https://twitter.com/m4d_z",
      speakerLinkedinPath: "https://www.linkedin.com/in/m4d-z/",
      speakerAbstractTitle: `Like a Newborn UI: I Want It Painted, App!`,
      speakerAbstractText: `Theming is one of the most common and most difficult tasks when building a consistent UI experience for our end-users. How should we variabilize elements and content? How should we adapt visual styles? What if our users need adapted modes for their disabilities? What if our users enable a Dark variant? Or disable animations? Or…

      There are many use-cases and many questions, only one answer: Design Tokens. Using Tokens, you should be able to embrace any situation without fearing unexpected behaviors. But how should we use those tokens?
      
      Here's a guide on using Tokens in your apps, from using regular CSS Custom Properties the right way in 2022; to extensively using styling libraries theming features like with Stitches or styled-components.`,
      speakerLogo: require("../img/Speakers/RIOTS.png").default
    },
    // {
    //   speakerPhoto: require("../img/Speakers/Akshat Paul.png").default,
    //   speakerName: "Akshat Paul",
    //   speakerPosition: "Founder & CTO",
    //   speakerTrack: "Junior",
    //   speakerTwitterPath: "https://twitter.com/paulakshat",
    //   speakerAbstractTitle: `Building Serverless mobile app with React Native and Amplify`,
    //   speakerAbstractText: `Learn how easy and quick it is to go live for your next mobile app with React Native bundled with AWS Amplify for back end services. In this talk will go though introduction about AWS amplify and how we can integrate fundamental features like authentication, backend APIs and database to build a super app super fast.`,
    //   speakerLogo: require("../img/Speakers/Company360.png").default
    // },
    {
      speakerPhoto: require("../img/Speakers/Tzemach.png").default,
      speakerName: "Elad Tzemach",
      speakerPosition: "Frontend Engineer",
      speakerTrack: "Senior",
      speakerLinkedinPath: "https://www.linkedin.com/in/elad-tzemach-020a16a4/",
      speakerAbstractTitle: `Server Components: The Future of React`,
      speakerAbstractText: `A little over a year ago the React team announced an exciting, new and zero-bundle-size way to architect performant React applications - Server Components. In this talk we will explore what Server Components are, why and when we might consider using them, how are they different from Server-Side Rendering, as well as see them in action with a quick demo! Join this talk to learn about this exciting new pattern and what the future of React looks like.`,
      speakerLogo: require("../img/Speakers/Amazon.png").default
    },
    {
      speakerPhoto: require("../img/Speakers/Ridhal.png").default,
      speakerName: "Sumit Ridhal",
      speakerPosition: "Senior Software Engineer",
      speakerTrack: "Junior",
      speakerLinkedinPath: "https://www.linkedin.com/in/sumitridhal",
      speakerAbstractTitle: `Introduction to Generative Art`,
      speakerAbstractText: `This is introductory talk to start with creative coding.
      Fundamental of Generative Art will cover 
      a. What is Generative Art?
      b. Fundamental Elements
      c. Control Functions e.g random vs noise
      d. Fractals
      
      The final result will be building a kaleidoscope app in react.
      And will expose the controls to dictate the final result. This has less to do with React but this can serves as a base to next chapter building: 
      a. 3D scenes in React application
      b. AR/VR app using React`,
      speakerLogo: require("../img/Speakers/Red Hat.png").default
    },
    {
      speakerPhoto: require("../img/Speakers/Briscoe.png").default,
      speakerName: "Matt Briscoe",
      speakerPosition: "Senior Computer Scientist",
      speakerTrack: "Senior",
      speakerLinkedinPath: "https://www.linkedin.com/in/mattbriscoe/",
      speakerAbstractTitle: `Component Driven Micro-frontends`,
      speakerAbstractText: `In order to scale to enterprise needs, Adobe has been adopting a UI modernization strategy that consists of component driven development, including building blocks all the way up to sophisticated micro-frontends to achieve wide enterprise success. The patterns and concepts discussed can be applied from small projects all the way up to large-scale enterprises, and ensure consistent and high quality developer and user experiences.`,
      speakerLogo: require("../img/Speakers/Adobe.png").default
    },
    {
      speakerPhoto: require("../img/Speakers/Tibaldi.png").default,
      speakerName: "Nicole Tibaldi",
      speakerPosition: "Senior Engineering Manager",
      speakerTrack: "Senior",
      speakerTwitterPath: "https://twitter.com/nicoletibaldi",
      speakerAbstractTitle: `Getting up to speed: onboarding strategies for successful teams`,
      speakerAbstractText: `Onboarding is one of the most important, and often least well-thought-out problems for engineering teams. Whether you're an engineer or manager, new joiner or existing teammate, at a new company or transferring internally -- you've had to approach this problem before, and certainly will again.

      In this talk, I'll present strategies for helping you set yourself and others up for success: how to manage expectations, make goals measurable, and structure the presentation of information to new teammates.
      
      Sharing of past onboarding mishaps will be optional, but encouraged - I know I have plenty!`,
      speakerLogo: require("../img/Speakers/The New York TImes.png").default
    },
    {
      speakerPhoto: require("../img/Speakers/Bahtijaragic.png").default,
      speakerName: "Meris Bahtijaragic",
      speakerPosition: "Senior Security Engineer at Spotify",
      speakerTrack: "Senior",
      speakerLinkedinPath: "https://www.linkedin.com/in/meris-bahtijaragic-%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BB-342a5883/",
      speakerTwitterPath: "https://twitter.com/merisbahti",
      speakerGithubPath: "https://github.com/merisbahti",
      speakerAbstractTitle: `Functional optics in react components`,
      speakerAbstractText: `I'll show how to compose react state like a tree, using functional programming, specifically a method called functional optics, also called bidirectional transformation.
      We'll build a highly interactive app, with dynamic state. Using functional optics, we'll make it easy to express this code. Another benefit is that we will get a high level of performance, without having to worry about any performance micro-optimizations such as React.memo.
      
      This one is for people curious about advanced topics in functional programming, and how to practically apply them in UI engineering.
      
      I've built my own library for this:
      https://github.com/merisbahti/klyva.
      
      But the principles are implmented in jotai:
      https://github.com/pmndrs/jotai/blob/main/docs/advanced-recipes/large-objects.mdx.`,
    },
    {
      speakerPhoto: require("../img/Speakers/Shiralizadeh.png").default,
      speakerName: "Mohamad Shiralizadeh",
      speakerPosition: "Architecture Owner",
      speakerTrack: "Senior",
      speakerLinkedinPath: "https://www.linkedin.com/in/shiralizadeh/",
      speakerAbstractTitle: `React forget`,
      speakerAbstractText: `Isn't interesting if I told you that you don't need to use React.memo() anymore.
      In this talk, we will get onto the deepest aspects of React's memorization and learn how you can do memoizing without using React.memo(). Finally, we will take a look at the newest method to optimize your app with React Forget compiler. 
      So, stay tuned!`,
      speakerLogo: require("../img/Speakers/UCraft.png").default
    },
    {
      speakerPhoto: require("../img/Speakers/Serasinghe.png").default,
      speakerName: "Sahan Serasinghe",
      speakerPosition: "Software Engineer",
      speakerTrack: "Senior",
      speakerLinkedinPath: "https://www.linkedin.com/in/sahanserasinghe/",
      speakerTwitterPath: "https://twitter.com/_SahanSera",
      speakerGithubPath: "https://github.com/sahansera",
      speakerAbstractTitle: `Infusing intelligence to your React Apps`,
      speakerAbstractText: `Think for a moment, when was the last time you searched online for something? Did you find what you were looking for? How do we give our customers a better user experience with AI-powered suggestions? In this talk, we will go through some of the technical challenges and a reference architecture for leveraging modern AI solutions in your React frontends to provide a seamless experience to your users.`,
      speakerLogo: require("../img/Speakers/GitHub.png").default
    },
    {
      speakerPhoto: require("../img/Speakers/Nisi.png").default,
      speakerName: "Nick Nisi",
      speakerPosition: "Staff Engineer",
      speakerTrack: "Junior",
      speakerTwitterPath: "https://twitter.com/nicknisi",
      speakerAbstractTitle: `Componentizing Application State`,
      speakerAbstractText: `Your application state is too complex. There are too many states to manage, it's difficult to test in isolation, and fidelity is lost when translating from high-level abstracts into runnable code. But, what if there was a better way? What if you could model your application into a finite number of states and test it in isolation, as if it were just another component in your application? In this talk, we'll explore modeling application state with XState while utilizing Storybook and React to treat it as if it were just another component that can be visually reviewed and understood by both the development and product teams, and can be tested independently of any UI code. At the end of this talk, you will have a baseline of what XState is and how it can be used within a React application, and you'll hear about how we did just that to manage some of the most complex pieces of our application.`,
      speakerLogo: require("../img/Speakers/C2FO.png").default
    },
    {
      speakerPhoto: require("../img/Speakers/Goldberg.png").default,
      speakerName: "Josh Goldberg",
      speakerPosition: "Open Source Developer",
      speakerTrack: "Senior",
      speakerTwitterPath: "https://twitter.com/JoshuaKGoldberg",
      speakerGithubPath: "https://github.com/JoshuaKGoldberg",
      speakerAbstractTitle: `Beyond Tailwind: What’s Next for Styling Components`,
      speakerAbstractText: `First there was CSS, and it was confusing and hard to keep track of. Then we added CSS modules for organization, followed by CSS-in-JS for tighter style and component integrations. Now the latest and greatest applications are split between Tailwind classes at smaller scale and styled systems at larger scale. I’d like to walk us through compare-and-contrast overviews approaches and the excellent next steps the industry is moving towards with type-safe theme contexts on styled systems.`,
    },
    {
      speakerPhoto: require("../img/Speakers/Jiang.png").default,
      speakerName: "Johnny Jiang",
      speakerPosition: "Tech Lead",
      speakerTrack: "Senior",
      speakerLinkedinPath: "https://www.linkedin.com/in/johnnycjiang/",
      speakerAbstractTitle: `How CORS and Third Party Cookies works in modern web`,
      speakerAbstractText: `Many webapps need to request resources from other web services. What are CORS and Third Party Cookies, what are their limitations, and how they are changing.`,
      speakerLogo: require("../img/Speakers/Pinterest.png").default
    },
    {
      speakerPhoto: require("../img/Speakers/Obando.png").default,
      speakerName: "Jean Obando",
      speakerPosition: "Software Developer",
      speakerTrack: "Junior",
      speakerLinkedinPath: "https://www.linkedin.com/in/jean-karlo-obando-ramos",
      speakerWebsitePath: "https://jeank.tech/",
      speakerGithubPath: "https://github.com/boceto1",
      speakerAbstractTitle: `React + Solid Project, Building our first decentralized App`,
      speakerAbstractText: `Tim Berners-Lee has been working on a project to build a new and safe web where the data property owns to the users not to the big companies. In this presentation we are going to explore the principles behind Solid and we are going to build our first decentralized app using React and Solid Project.`,
      speakerLogo: require("../img/Speakers/Maestro IO.png").default
    },
    {
      speakerPhoto: require("../img/Speakers/Ivanov.png").default,
      speakerName: "Petar Ivanov",
      speakerPosition: "Software Craftsman",
      speakerTrack: "Junior",
      speakerLinkedinPath: "https://www.linkedin.com/in/petarivanov9/",
      speakerGithubPath: "https://github.com/petarivanov9/",
      speakerAbstractTitle: `Bootstrap to Tailwind and Beyond`,
      speakerAbstractText: `How did we migrate from Angular to React & Bootstrap and then from Bootstrap to Tailwind?
      We will go step by step to the migration journey we went through with one of our clients regarding their customer-facing web application.
      We will go through:
      - our migration strategy and the why behind it;
      - some of the failures we had and the lessons we have learned from them;
      - some anti-patterns and some good practices from our own experience and point of view.`,
      speakerLogo: require("../img/Speakers/ST6.png").default
    },
    {
      speakerPhoto: require("../img/Speakers/Park.png").default,
      speakerName: "Joon Park",
      speakerPosition: "Senior Software Engineer",
      speakerTrack: "Junior",
      speakerLinkedinPath: "https://www.linkedin.com/in/joonpark13/",
      speakerGithubPath: "https://github.com/Joonpark13",
      speakerAbstractTitle: `A Deep Dive into React Keys (they're not just for lists!)`,
      speakerAbstractText: `We all know it, love it, hate it: that infamous error which says "Each child in a list should have a unique "key" prop." Sure, you usually just slap an id on that node and call it a day. But did you know that React keys are useful outside the context of lists? By doing a deep dive into exactly what React keys are and why they’re there, we’ll discover some unexpected use cases for React keys.`,
    },
    {
      speakerPhoto: require("../img/Speakers/Daei.png").default,
      speakerName: "Mehri Daei",
      speakerPosition: "Frontend Developer",
      speakerTrack: "Junior",
      speakerLinkedinPath: "https://www.linkedin.com/in/mehridaei/",
      speakerAbstractTitle: `High-performance PWA`,
      speakerAbstractText: `Progressive Web Applications (PWAs) are apps built with web technologies that we
      probably all know and love, like HTML, CSS, and JavaScript. But they have the feel and
      functionality of an actual native app. with PWA, apps are built quickly and easily and
      have the ability to run on android and ios devices without much difference. So usage of
      PWA in the web development world is increasing and will be increasing every day too.
      But it should be considered that every PWA needs to be implemented with high
      performance which users can use easily and become more like native apps. Now that
      we know about the importance of PWAs let's talk about points that help us to have
      high-performance PWAs.`,
      speakerLogo: require("../img/Speakers/SnappDoctor.png").default
    },
    {
      speakerPhoto: require("../img/Speakers/Shah.png").default,
      speakerName: "Harshil Shah",
      speakerPosition: "Technical Lead",
      speakerTrack: "Junior",
      speakerLinkedinPath: "https://www.linkedin.com/in/harshil-shah-76b24a146",
      speakerTwitterPath: "https://twitter.com/harshilpossible",
      speakerAbstractTitle: `React routing V6`,
      speakerAbstractText: `The traditional way of react routing, up to react router V5, required us to add custom HOCs and wrappers to handle advanced routing in React apps.

      React router V6 to the rescue where we will walk through lot of new features like Auth guard support, layout support and many so will make it super streamlined to add advanced routing to React apps.`,
      speakerLogo: require("../img/Speakers/Velotio Technologies.png").default
    },
];

export default speakerInfoHide;
