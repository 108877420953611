import React from "react";
import "./partners.css";
import { Container, Row, Col } from "react-bootstrap";
import PartnersInfofrom from "../../store/PartnersInfo.js";
import PartnersChild from './partners/partners.jsx'
 
const Partners = () => {
  let partnersElements = PartnersInfofrom.map((partnersItem) => {
    return <PartnersChild partners={partnersItem} />;
  });
  return (
    <div className="partners">
      <Container>
        <Row>
          <Col>
            <div className="partners-title">
              <h2 className="title">{"<Partners>"}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="partners-photos">
              <div className="partners-photos-line">{partnersElements}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Partners;
