let PartnersInfo = [
  {
    id: 1,
    partnersLink: 'https://apiumhub.com/',
    partnersCompany: "apiumhub",
    partnersLogo: require("../img/partners/apiumhub.png").default,
  },
  {
    id: 2,
    partnersLink: 'https://vihattechnologies.com/',
    partnersCompany: "vihattechnologies",
    partnersLogo: require("../img/partners/vihat.png").default,
  },
  {
    id: 3,
    partnersLink: 'https://swissdevjobs.ch/events',
    partnersCompany: "swissdevjobs",
    partnersLogo: require("../img/partners/swissdevjobs.png").default,
  },
  {
    id: 4,
    partnersLink: 'https://developers.google.com/community/gdsc',
    partnersCompany: "Google Developer Student Clubs",
    partnersLogo: require("../img/partners/google_student.png").default,
  },
  {
    id: 5,
    // partnersLink: 'https://developers.google.com/community/gdsc',
    partnersCompany: "Women in Tech collectives, India",
    partnersLogo: require("../img/partners/women-tech.png").default,
  },
]

export default PartnersInfo;
