import React from "react";
import "./organizers.css";
import { Container, Row, Col } from "react-bootstrap";

const Organizers = () => {
  return (
    <div className="organizers main_block">
      <Container>
        <Row>
          <Col>
            <div className="organizers_title">
              <h2 className="title title--black">{"<Our Team>"}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="organizers_col">
            <a
              href="https://www.facebook.com/people/Ed-Nedin/100007958711953/"
              target="_blank"
              rel="noreferrer"
              className="organizers_block_wrapper organizers_block--Ed"
            >
              <div className="organizers_block">
                <p className="organizers_name">Ed Nedin</p>
                <div className="organizers_info">
                  <p className="organizers_position">
                    Chief Executive Officer Geekle
                  </p>
                  <a
                    className="organizers_link organizers_link--facebook"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/profile.php?id=100007958711953"
                  ></a>
                </div>
              </div>
            </a>
          </Col>
          <Col className="organizers_col">
            <a
              className="organizers_block_wrapper organizers_block--Anna"
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/anna-metelska/"
            >
              <div className="organizers_block">
                <p className="organizers_name">Anna Metelska</p>
                <div className="organizers_info">
                  <p className="organizers_position">Community Manager</p>
                  <div className="organizers_links">
                    <a
                      className="organizers_link organizers_link--ln"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/in/anna-metelska/"
                    ></a>
                    <a
                      className="organizers_link organizers_link--mail"
                      target="_blank"
                      rel="noreferrer"
                      href="mailto:anna.m@geekle.us"
                    ></a>
                  </div>
                </div>
              </div>
            </a>
          </Col>
          <Col className="organizers_col">
            <a
              className="organizers_block_wrapper organizers_block--Pavel"
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/pavel.pankratov.14"
            >
              <div className="organizers_block">
                <p className="organizers_name black">Pavlo Pankratov</p>
                <div className="organizers_info">
                  <p className="organizers_position black">
                    Partner Success Manager
                  </p>
                  <a
                    className="organizers_link organizers_link--facebook"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/pavel.pankratov.14"
                  ></a>
                </div>
              </div>
            </a>
          </Col>
          <Col className="organizers_col">
            <a
              className="organizers_block_wrapper organizers_block--Nik"
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/nik-petrov-828b4119b/"
            >
              <div className="organizers_block">
                <p className="organizers_name black">Nik Petrov</p>
                <div className="organizers_info">
                  <p className="organizers_position black">
                    Content Production Lead
                  </p>
                  <a
                    className="organizers_link organizers_link--ln"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/in/nik-petrov-828b4119b/"
                  ></a>
                </div>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Organizers;
