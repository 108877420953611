import "./OpenMenu.css";
import close from "../../../../img/close.svg";
const OpenMenu = (props) => {
  const smoothScroll = (target) => {
    const { top } = target.getBoundingClientRect();
    window.scrollTo({
      top: top + window.pageYOffset,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="openMenu">
        <img src={close} onClick={props.close} alt="error" />
        <ul>
          <li onClick={props.close}>
            <a href="#" onClick={() => smoothScroll(props.speakers.current)}>
              Speakers
            </a>
          </li>
          <li onClick={props.close}>
            <a href="#" onClick={() => smoothScroll(props.workshops.current)}>
              Workshops
            </a>
          </li>
          {/* <li>
            <a href="#" onClick={() => smoothScroll(props.pc.current)}>
              Committee
            </a>
          </li> */}
          <li onClick={props.close}>
            <a href="#" onClick={() => smoothScroll(props.packages.current)}>
              Tickets
            </a>
          </li>
          <li onClick={props.close}>
            <a href="#" onClick={() => smoothScroll(props.enterprise.current)}>
              Enterprise
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
export default OpenMenu;
