let WorkshopInfo = [
  {
    id: 1,
    workshopTime: "April 28, 3 PM GMT, 3 hours",
    workshopAbstractTitle: `"Props, Mocks, and Context: Unit Testing Heavy Components Workshop"`,
    workshopName: "Josh Goldberg",
    workshopPosition: "Open Source Developer",
    workshopPrice: 199,
    workshopAvatar: require("../img/workshops/Josh Goldberg.png").default,
    workshopLink: "https://events.geekle.us/workshops/JoshGoldberg/",
    workshopTicket: "https://events.geekle.us/workshops/JoshGoldberg#tickets",
  },
];

export default WorkshopInfo;
