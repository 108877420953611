import './partners.css'
import React from "react";

const Partners = (props) => {
  return (
    <a href={props.partners.partnersLink} target='_blank'>
        <img src={props.partners.partnersLogo} alt={props.partners.partnersCompany} />
    </a>
  );
};

export default Partners;