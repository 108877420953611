import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReviewIcon from "../../../img/review_icon.png";

const Reviews = (props) => {
  return (
    <Col className="review_wrap">
        <div className="review">
            <img
            className="review_photo"
            src={props.reviews.reviewsAvatar}
            alt={'photo' + props.reviews.reviewsName}
            />
            <div className="review_info">
            <img className="review_icon" src={ReviewIcon} alt="icon" />
            <p className="review_name">{props.reviews.reviewsName}</p>
            <p className="review_position">{props.reviews.reviewsPosition}</p>
            <p className="review_text">{props.reviews.reviewsText}</p>
            </div>
        </div>
    </Col>
  );
};

export default Reviews;
