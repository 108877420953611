import React from "react";
import "./packages.css";
import { Container, Row, Col } from "react-bootstrap";
import done from "../../img/done.svg";
import none from "../../img/none.svg";
import Marquee from 'react-double-marquee';

const Packages = () => {
  // const utm = Object.fromEntries((window.location.href.match(/(?:utm_).+?=[^&]*/g) || []).map(n => n.split('=')));
  const pageLink = window.location.href.toString(); 
  const indexUtm = pageLink.indexOf('?');
  const utm = pageLink.slice(indexUtm+1);

  return (
    <div className="packages" id="packages">
      <Container>
        <Row>
          <Col>
            <div className="packages-title">
              <h2 className="title">{"<Tracks and Prices>"}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="packages-block">
              {/* <div className="packages-text">
                <span>What's Included</span>
                <span className="blue">What's Included</span>
                <span>What's Included</span>
                <span className="blue">What's Included</span>
              </div> */}
              <div className="packages-text">
              <Marquee delay={0} childMargin={0}>
                  <span className="green"> What's Included</span><span>What's Included</span><span className="green"> What's Included</span><span>What's Included</span><span className="green"> What's Included</span><span>What's Included</span><span className="green"> What's Included</span><span>What's Included</span><span className="green"> What's Included</span><span>What's Included</span><span className="green"> What's Included</span><span>What's Included</span><span className="green"> What's Included</span><span>What's Included</span><span className="green"> What's Included</span><span>What's Included</span><span className="green"> What's Included</span><span>What's Included</span><span className="green"> What's Included</span><span>What's Included</span><span className="green"> What's Included</span><span>What's Included</span><span className="green"> What's Included</span><span>What's Included</span><span className="green"> What's Included</span><span>What's Included</span>
              </Marquee>
              </div>

              <div className="packages-block-1">
                <div className="packages-block-title">
                  <h2>Junior Track</h2>
                  <p>for React JS Developers</p>
                </div>
                <div className="packages-block-info packages-block-info-1">
                  <div>
                    <img src={none} alt="None" />
                    <p>No recordings</p>
                  </div>
                  <div>
                    <img src={done} alt="Done" />
                    <p>Live stream</p>
                  </div>
                  <div>
                    <img src={done} alt="Done" />
                    <p>{"Q&A panel participation"}</p>
                  </div>
                </div>
                <hr />
                <div className="packages-block-price">
                  <h3>Free</h3>
                  <a
                    href={`https://geekle.us/ticketfree/1635346900748x653042076027453400?page=react${indexUtm !== -1 ? `&${utm}` : ''}`}
                    // href={`https://geekle.us/ticketfree/1635346900748x653042076027453400?page=react2${(utm.utm_campaign ? `&utm_campaign=${utm.utm_campaign}` : '')}${(utm.utm_source ? `&utm_source=${utm.utm_source}` : '')}${(utm.utm_medium ? `&utm_medium=${utm.utm_medium}` : '')}${(utm.utm_term ? `&utm_term=${utm.utm_term}` : '')}${(utm.utm_content ? `&utm_content=${utm.utm_content}` : '')}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button>Register</button>
                  </a>
                </div>
              </div>
              <div className="packages-block-2">
                <div className="packages-block-title">
                  <h2>Senior Track</h2>
                  <p>
                    for experienced React JS Developers. Deep
                    tech content.
                  </p>
                </div>
                <div className="packages-block-info">
                  <div>
                    <img src={done} alt="Done" />
                    <p>Recordings of both tracks</p>
                  </div>
                  <div>
                    <img src={done} alt="Done" />
                    <p>Live stream for both tracks</p>
                  </div>
                  <div>
                    <img src={done} alt="Done" />
                    <p>{"Q&A panel participation"}</p>
                  </div>
                  <div>
                    <img src={done} alt="Done" />
                    <p>Slack membership</p>
                  </div>
                  <div>
                    <img src={done} alt="Done" />
                    <p>Certificate of attendance</p>
                  </div>
                </div>
                <hr />
                <div className="packages-block-price">
                  <h3>Price $350</h3>
                  <a
                    // href={`https://geekle.us/ticket/1635346619732x340231401410396160?page=react2${(utm.utm_campaign ? `&utm_campaign=${utm.utm_campaign}` : '')}${(utm.utm_source ? `&utm_source=${utm.utm_source}` : '')}${(utm.utm_medium ? `&utm_medium=${utm.utm_medium}` : '')}${(utm.utm_term ? `&utm_term=${utm.utm_term}` : '')}${(utm.utm_content ? `&utm_content=${utm.utm_content}` : '')}`}
                    href={`https://geekle.us/ticket/1635346619732x340231401410396160?page=react${indexUtm !== -1 ? `&${utm}` : ''}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button>Purchase</button>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Packages;
