import React from "react";
import "./reviews.css";
import { Container, Row, Col } from "react-bootstrap";
import ReviewsInfofrom from "../../store/ReviewsInfo";
import ReviewsChild from './Reviews/reviews.jsx'

const Reviews = () => {
  let reviewsElements = ReviewsInfofrom.map((reviewsItem) => {
    return <ReviewsChild reviews={reviewsItem} />;
  });
  return (
    <div className="reviews main_block">
      <Container>
        <Row>
          <Col>
            <div className="reviews_title">
              <h2 className="title">
                {"<What Attendees Say About Our Events>"}
              </h2>
            </div>
          </Col>
        </Row>
        <Row>{reviewsElements}</Row>
      </Container>
    </div>
  );
};
export default Reviews;
