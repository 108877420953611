import "./ModalAgenda.css";
import { Container } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";
const ModalAgenda = (props) => {
  return (
    <Modal {...props}>
      <Modal.Header className="modal-header">
        <h1>{props.agendaTitle}</h1>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <p>{props.agendaAbstractText}</p>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAgenda;
