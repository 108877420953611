import React from "react";
import "./Workshop.css";
const Workshop = (props) => {
  return (
      <div className="Workshop_wrapper">
        <div className="Workshop">
          <div className="WorkshopImg_wrap">
            <img className="WorkshopImg" src={props.workshop.workshopAvatar} />
            <h3 className="WorkshopFirstInfoName">
              Workshop #{props.workshop.id}
            </h3>
            <p className="WorkshopDate">{props.workshop.workshopTime}</p>
          </div>
          <div className="WorkshopFirstInfo">
            <p className="WorkshopTitle">{props.workshop.workshopAbstractTitle}</p>
          </div>
          <div className="WorkshopLetterInfo">
            <div>
              <h3 className="WorkshopLetterInfoName">
                {props.workshop.workshopName}
              </h3>
              <p className="WorkshopPosition">{props.workshop.workshopPosition}</p>
              <img className="WorkshopLogo" src={props.workshop.workshopLogo} />
            </div>
          </div>
        </div>
        <div className='Workshop-btn-wrap'>
            <a className="WorkshopBtn" href={props.workshop.workshopLink} target="_blank" rel="noreferrer">Learn More</a>
            <a className="WorkshopBtn" href={props.workshop.workshopTicket} target="_blank" rel="noreferrer">Book For {props.workshop.workshopPrice}$</a>
        </div>
      </div>
  );
};

export default Workshop;
